import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Ben Bryant </span>
            from <span className="purple"> Newcastle Upon Tyne.</span>
            <br /> I am a self taught developer with multiple languages and alot of experience.
            <br />
            Additionally, I am currently working on my own projects.
            Such as a few personal project such as <span className="purple">Ascension </span>
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
            <li className="about-activity">
              <ImPointRight /> Photography
            </li>
          </ul>

          <footer className="blockquote-footer">Bweno</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
